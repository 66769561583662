.mc {
    a {
        @extend %transitions;
        &.is-highlighted-link {
            display: inline-block;
            font-size: 1rem;
            text-transform: uppercase;
            font-weight: 700;
            > svg, i {
                margin-left: .5rem;
                color: $med-green;
            }
        }
    }
}