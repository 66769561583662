// Trial search
.mc {

    .trial-search-finder-footer {
        background: darken(#e1ebe6,4%);
        border-bottom-left-radius: $uicard-border-radius;
        border-bottom-right-radius: $uicard-border-radius;
        padding: 2rem;  
    }

    .trial-search-finder {

        background: #e1ebe6;
        border-top-left-radius: $uicard-border-radius;
        border-top-right-radius: $uicard-border-radius;
        display: block;
        padding: 0 1.5rem 1.5rem 1.5rem;
        position: relative;
        font-size: 2.5rem;
        width: 100%;

        @include from(850px) {
            padding: 2.5rem;
        }

        .is-zipcode {
            width: 160px;
            margin: 1rem 0;
            display: block;
            @include from(850px) {
                margin: 0;
                display: inline;
                vertical-align: middle;
            }
            font-size: 2rem;
        }

        .is-range {
            width: 70%;
            vertical-align: -10%;
            display: inline-block;
            @include from(850px) {
                width: 400px;
            }
        }

        .range-miles {
            vertical-align: 1%;
            font-weight: 800;
            height: 72px;
            width: 72px;
        }

        .is-middle {
            display:inline-block;
            margin: 1.5rem 0 1rem 0;
        }

        .is-conditions-container {
            @include from(800px) {
                display: inline-block;
            }
        }

        &-content {
            line-height: 6rem;
            @include from(800px) {
                max-width: 88%;
            }
            .dropdown-button {
                vertical-align: 6%;
            }
        }

    }
}

$trial-finder-full: 986px;
$trial-finder-medium: 700px;
.mc {
    .trial-finder {
        font-size:1.5rem;
        line-height:3.3rem;
        border: solid 3px lighten(#d2e1d9,5%);
        background: lighten(#d2e1d9,5%);
        border-radius: 5px;
        display: block;
        padding: 2.5rem;
        position: relative;
        @include from($trial-finder-full) {
            display: inline-block;
        }
        label {
            position: absolute;
            top: -28px;
            text-transform: uppercase;
            font-size: 1.1rem;
            font-weight: 900;
            background: #e9f0ec;
            padding: 0 1rem;
            color: #76b4ad;
        }
        &-row {
            @extend %transitions;
            @include from($trial-finder-full) {
                display: inline-block;
            }
        }
        &-section {
            @extend %transitions;
            margin-bottom: 1rem;
            @include from($trial-finder-medium) {
                display:inline-block;
            }
            @include from($trial-finder-full) {
                margin-bottom:0;
            }
            button {
                width: 100%;
                @include from($trial-finder-full) {
                    margin-left: 1rem;
                    width: auto;
                }
            }
        }
        &-btn {
            margin-bottom: 0;
        }
    }
}