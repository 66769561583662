.mc {
    section {
        &.section {
            
            &.is-top-section {
                margin-top: 1.4rem;
            }

            padding: $section-padding-mobile;
            @include tablet {
                padding: $section-padding;
            }
            
        }
    }

    header {
        &.is-title-cta {
            display: flex;
            flex-wrap: wrap;
            @include desktop {
                display: block;
            }
            > h1, h2, h3, h4, h5, h6 {
                order: 2;
                @include desktop {
                    display: inline-block;
                }
            }
            > div {
                flex-basis: 100%;
                margin-bottom: .5rem;
                @include desktop {
                    display: inline;
                    margin-left: 1rem;
                }
            }
        }
    }
}

.mc .is-section-label {
    text-transform: uppercase;
    margin-bottom: .5rem;
    color: #5b9984;
    display: inline-block;
    font-weight: 800;
    font-size:1rem;
}

.mc {
    .section-label {
        color: darken(#76b4ad,7%);
        line-height: 2rem;
        font-size: 1rem;
        font-weight: 800;
        text-transform: uppercase;
        &.is-small {
            font-weight: 600;
            font-size: 0.9rem;
            line-height: normal;
        }
    }
}

.mc {
    section {
        &.is-grey-bg {
            background: $light-grey;
        }
    }
}

.mc {
    section.is-light-green {
        background: lighten($light-green,2%);
    }
}