.mc {
    .is-client-logo {
        max-height: 5.5rem;
    }
    footer {
        
        .column {
            &:first-child {
                img {
                    max-width: 9rem;
                }
            }
            text-align: center;
            @include tablet {
                text-align: left;
            }
        }
        
        .has-footer-bg {
            background: darken($dark-grey,3%) !important;
        }
       
        small {
            font-size: 0.9rem;
        }
       
        &.main-site-bg {
            background: $dark-grey;
        }

        .copyright {
            background: $dark-grey;
            padding: 1.3rem;
            color: rgba(255,255,255,.5);
            text-align: center;
            @include tablet {
                text-align: left;
            }
            .is-inline-list li {
                margin-bottom: 0 !important;
            }
        }

        a.is-footer-link {
            @extend %fancy-link;
            border-bottom: none !important;
            font-size: 0.9rem !important;
            font-weight: 500 !important;
            color: #fff !important;
        }

        .is-footer-logo-title {
            background: lighten(#addae6,17%);
            font-size: 0.8rem;
            color: darken(#addae6,20%);
        }
        .footer-logo {
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .is-footer-logo {
            //background: #E9F0EC; //lighten(#addae6,20%);
            img {
                max-width: 250px;
                @include tablet {
                    max-width: 320px;
                }
            }
        }
        .textwidget {
            p {
                font-size: 1rem !important;
            }
        }
        .level-left + .level-right {
            @include mobile {
                margin-top: 0 !important;
            }
        }
    }
}

.mc.mc-leadgen {
    footer {
        background: lighten($light-green,3%);
        p {
            font-weight: 300;
        }
        a {
            @extend %fancy-link;
            border-bottom: none !important;
            font-size: 0.9rem !important;
            font-weight: 500 !important;
            color: #fff !important;
        }
    }
}

footer {
    h3 {
        display: inline-block;
        font-size: 1rem;
        font-family: $family-sans-serif;
        color: $text;
        font-weight: 700;
        margin-bottom: 1rem;
    }
}