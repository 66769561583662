$font-glober: ( 
    "glober-reg": 400,
    "glober-semibold": 500,
    "bold": 600,
    "extra-bold": 700,
    "black": 900
);

$font-overpass: ( 
    "Thin" : 100,
    "ExtraLight" : 200,
    "Light": 300,
    "Regular": 400,
    "SemiBold": 600,
    "Bold": 700,
    "ExtraBold": 800,
    "Black": 900
);