.mc {

    .tag {

        &.is-secondary {
            background: #e9f0ec;
            color: #76b4ad;
        }

    }

}