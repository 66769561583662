.mc {
    .is-img.is-lazy-bg {
        background-image: none !important;
        background-color: $primary-color;
    }
    .logo-tmp {
        line-height: 47px;
        margin-left: 24px;
        color: #76b4ae;
        font-weight: 700;
        padding: 6px 0;
    }
    .is-blur-img {
        filter: blur(50px);
        -webkit-filter: blur(50px);
        opacity: 0;
    }
    .is-no-blur {
        @extend %transitions;
        filter: none;
        -webkit-filter: none;
        opacity: 1;
    }
}