$css_margin_names: "margin-right", "margin-left", "margin-top", "margin-bottom", "padding-left", "padding-right", "padding-top", "padding-bottom", "margin", "padding";
@for $i from 1 through length( $css_margin_names ) {
    $property: #{nth($css_margin_names, $i)};
    $sizes: "0", "1", "2", "3", "4", "5", "6";
    @for $i_ from 1 through length( $sizes ) {
        .is-#{$property}-#{nth($sizes, $i_)} {
            #{$property}: #{nth($sizes, $i_)}rem !important; 
        }
        .is-#{$property}-half {
            #{$property}: 0.5rem;
        }
    }
}

// Util - break line
%break-line {
    content: "\A";
    white-space: pre;
}

%dont-break-out {

    /* These are technically the same, but use both */
    overflow-wrap: break-word;
    word-wrap: break-word;
  
    -ms-word-break: break-all;
    /* This is the dangerous one in WebKit, as it breaks things wherever */
    word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word;
  
    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
  
  }

.mc {

    .valign-bottom {
        margin-top: auto;
    }

}