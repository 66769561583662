.mc {
    .tabs-with-content {
        .tabs:not(:last-child) {
            margin-bottom: 0;
        }
        .tab-content {
            background: lighten($light-green,4%);
            @extend %transitions-slow;
            padding: 1rem;
            display: none;
            opacity: 0;
            &.is-active {
                display: block;
                opacity: 1;
            }
            .is-label {
                color: #4d6575;
                text-transform: uppercase;
                font-size: 1rem;
            }
            .is-value {
                font-weight: 500;
            }
        }
    }
}