%hero-background-image {
    background-size: cover;
    background-position: center center;
    //background-attachment: fixed;
}

.mc {
    .hero {
        &.hero-content-pg {

            .hero-body {
                z-index: 9;
                padding: 0 !important;
                background: lighten($lightest-green,0%);
                display: block;
                @include tablet {
                    display: flex;
                    align-items: stretch;
                }
                .columns {
                    > div {
                        &:last-child {
                            .container {
                                @include tablet {
                                    padding :3rem;
                                }
                            }
                        }
                    }

                    @include tablet {
                        min-width: 100vw;
                        align-items: stretch;
                    }
                    .column {
                        &:first-child {
                            div {
                                @extend %hero-background-image;
                                height: 50vh;
                                @include tablet {
                                    height: 100%;
                                }
                                position:relative;
                                &:after {
                                    position: absolute;
                                    display: block;
                                    content: '';
                                    top: 0;
                                    left: 0;
                                    right: 0;
                                    bottom: 0;
                                    z-index: 1;
                                    background-color: rgba($primary-color, 0.2);
                                }
                            }
                        }
                        &:last-child {
                            padding: 3rem;
                            @include tablet {
                                align-items: center;
                                display: flex;
                            }
                        }
                    }

                }
            }



            /*position: relative;
            &:after {
                position: absolute;
                display: block;
                content: '';
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 1;
                background-color: rgba(0, 0, 0, 0.2);
            }*/

            

            h1.title {
                font-weight: 700;
                display: inline-block;
                padding: 1rem 1.4rem;
                border-radius: 2px;
            }

        }
    }
}

.mc {
    .hero-front {
        position: relative;;
        background: #e9f0ec;
        h1, strong, h2 {
            color: #fff
        }
        h1 {
            font-weight: 700;
        }
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;

        &:after {
            position: absolute;
            display: block;
            content: "";
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
            background-color: rgba(118,180,173,0.6);
            @include from(1024px) {
                right: 55%;
                background-color: rgba(118,180,173,0.7);
            }
        }
        &-body {
            z-index:2;
            .container {
                z-index: 2;
            }
            > .section {
                padding: 0;
                @include tablet {
                    padding: 1.75rem;
                }
            }
        }
    }
}