.mc {

    a.dropdown-item, button.dropdown-item {
        white-space: normal;
        @include from (600px) {
            white-space: nowrap;
        }
    }

    .dropdown {
        button.dropdown-button {
            border-radius: 4px;
            font-size: 2rem;
            padding-left: 0.75em;
            padding-right: 0.75em;
            span {
                &.is-placeholder {
                    color: rgba(54, 54, 54, 0.3);
                }
                &:first-child {
                    max-width: 50vw;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
        .icon {
            color: $med-green;
        }
        &-menu {
            .dropdown-content {
                max-height:35vh;
                overflow:scroll;
                .dropdown-item {
                    font-size: 1.5rem;
                    &-indent {
                        padding-left: 2rem;
                    }
                    &.is-active {
                        background: $light-green;
                        color: darken($med-green,10%);
                        font-weight: 900;
                    }
                }
            }
        }
    }

}

