// Range picker
.is-range {

	&.is-full {
		input[type="range"] {
			min-width: 100% !important;
			width: 100% !important;
		}
	}

	&.is-large {
		$rangeHeight : 60px;
		input[type="range"] {
			height: $rangeHeight;
			@media screen and (-webkit-min-device-pixel-ratio:0) {
				& {
					height: $rangeHeight;
				}
				&::-webkit-slider-runnable-track {
					height: $rangeHeight;
				}
				&::-webkit-slider-thumb {
					height: $rangeHeight;
				}
			}
		}
		+ .is-range-display {
			height: $rangeHeight;
			min-width: $rangeHeight+5px;
			line-height: $rangeHeight;
			vertical-align: 14%;
			margin-top: 1rem;
			+ span {
				line-height: $rangeHeight;
				display: inline-block;
				vertical-align: 14%;
				margin-left: .5rem;
			}
		}
	}

	// now for the good stuff 🎉
	input[type="range"] {
        vertical-align: -90%;
		outline: 0;
		border: 0;
        border-radius: 500px;
        border-radius: $uicard-border-radius/2;
		width: 400px;
		max-width: 100%;
		/*margin: 0 1rem;*/
		transition: box-shadow 0.2s ease-in-out;
		// Chrome
		@media screen and (-webkit-min-device-pixel-ratio:0) {
			& {
				overflow: hidden;
				height: 72px;
				-webkit-appearance: none;
				background-color: #d2e1d9;
			}
			&::-webkit-slider-runnable-track {
				height: 72px;
				-webkit-appearance: none;
				color: #d2e1d9;
				// margin-top: -1px;
				transition: box-shadow 0.2s ease-in-out;
			}
			&::-webkit-slider-thumb {
				width: 40px;
				-webkit-appearance: none;
				height: 72px;
				cursor: ew-resize;
				background: darken(#d2e1d9,5%);
				box-shadow: -340px 0 0 320px darken(#d2e1d9,5%), inset 0 0 0 40px darken(#d2e1d9,5%);
                border-radius: 50%;
                border-radius: $uicard-border-radius/2;
				transition: box-shadow 0.2s ease-in-out;
				position: relative;
				//top: 10px;
			}
			&:active::-webkit-slider-thumb {
				background: darken(#d2e1d9,5%);
				box-shadow: -340px 0 0 320px darken(#d2e1d9,5%), inset 0 0 0 3px darken(#d2e1d9,5%);
			}
		}
		// Firefox
		&::-moz-range-progress {
			background-color: #43e5f7;
		}
		&::-moz-range-track {
			background-color: #d2e1d9;
		}
		// IE
		&::-ms-fill-lower {
			background-color: #43e5f7;
		}
		&::-ms-fill-upper {
			background-color: #d2e1d9;
		}
	}
}