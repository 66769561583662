// Forms
.mc {
    .field {
        &.is-inline {
            display: block !important;
            @include tablet {
                display: inline !important;
            }
        }
    }
}

.mc {
    form {
        .is-input-with-label {
            position: relative;
        }
        .input-label {
            position: absolute;
            top: -11px;
            display: inline-block;
            background: #fff;
            z-index: 9;
            left: 20px;
            padding: 0 10px;
            text-transform: uppercase;
            font-weight: 700;
            font-size: 1rem;
            position: relative;
            left: 0;
            padding: 0;
            background: none;
            color: #4d6575;
            background: #E9F0EC;
            padding: 2px 5px;
            border-radius: 3px;
        }
    }
}

.mc .mc-form-container {
    background: lighten($primary, 45%);
    h2 {
        color: $secondary;
        font-weight: 800;
    }
    & > .is-form-title {
        background: $primary;
        padding:1.3rem;
    }
}

.is-danger {
    border-color: #ff3860 !important;
}