.mc {
    .board {
        @include vendor(column-count,1);
        @include vendor(column-gap,2rem);
        @include tablet {
            @include vendor(column-count,2);
        }
        @include desktop {
            @include vendor(column-count,3);
            &.is-2 {
                @include vendor(column-count,2);
            }
        }
        @include from(1200px) {
            margin-left: -10%;
            width: 120%;
        }
        h1,h2,h3,h4,h5 {
            a {
                color: $dark-grey;
                font-weight: 900;
            }
        }
        &-item {
            background: white;
            margin: 0 0 2rem 0;
            padding: 2rem;
            text-align: left;
            -webkit-column-break-inside: avoid;
            -moz-column-break-inside: avoid;
            break-inside: avoid;
            border-radius: $uicard-border-radius;
            border: solid 3px $white;
            transition: all .4s ease;
            &:hover {
                text-decoration: none;
                box-shadow: 8px 20px 30px rgba(39,44,49,.05), 1px 6px 12px rgba(39,44,49,.04);
                transform: translate3D(0,-1%,0) scale(1.02);
                border-color: $med-green;
            }
        }

    }

    ul.column-content {
        background:$white;
        border-radius:$uicard-border-radius;
        padding:3rem;
        list-style-type: none;
        margin-left: 0!important;
        @include vendor(column-count,1);
        @include vendor(column-gap,2rem);
        @include tablet {
            @include vendor(column-count,2);
        }
        @include desktop {
            @include vendor(column-count,3);
        }
        @include from(1200px) {
            margin-left: -10% !important;
            width: 120%;
        }


    }

}