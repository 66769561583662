.mc {
    .button {
        &.dropdown-button {
            &:focus {
                box-shadow: 0 0 0 0.125em rgba($med-green, 0.25);
                border-color: $med-green;
            }
            &.is-error {
                box-shadow: 0 0 0 0.125em rgba($red, 0.25);
                border-color: $red;
            }
        }
    }
}