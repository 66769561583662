.mc {
    .table {
        tr {
            td {
                border: none;
            }
            td:first-child {
                background: #E9F0EC;
                border-radius: 5px;
                color: #76b4ad;
                font-weight: 700;
            }
            margin-bottom: 1rem;
        }
    }
}