.mc {
    
  .modal {

    @include transitions(transform,500ms,ease);

    display: flex;
    width: 100vw;
    max-width: 100vw;

    @include vendor(transform, translate3d(100%, 0, 0));

    @include desktop {
      width: 50vw;
      max-width: 50vw;
      @include vendor(transform, translate3d(200%, 0, 0));
    }

    .modal-content {
      width: 100% !important; 
      padding: 0 1.5rem 1.5rem 1.5rem;
      @include tablet {
        padding: 0 3rem 3rem 3rem;
      }
      p, h1, h2, h3, h4, h5 {
        color: darken($light-green,5%);
      }
      &-block {
        background: rgba( $white, 0.1 );
        padding: 1rem;    
        border-radius: $uicard-border-radius;
        p, span, strong, div {
          color: white;
        }
        select, input {
          border: none !important;
          background: rgba(255,255,255,0.2);
        }
      }
    }

    .modal-background {
      background: rgba( darken( $primary-color, 10% ), 0.99 );
    }

    &.is-modal-open {
      @include transitions(transform,500ms,ease);
      @include vendor(transform, translate3d(0, 0, 0));
      @include desktop {
        @include vendor(transform, translate3d(100%, 0, 0));
      }
    }

    &.is-modal-visible {
      opacity: 1;
    }

    &.is-modal-hidden {
      opacity: 0;
    }

  }

}