.mc {

    header {

        nav {

            &.navbar {
                background: lighten($light-green,6%);
                &.has-shadow {
                    box-shadow: 0 1px 0 0 $light-green;
                }
            }

            .navbar-brand {
                margin-left: 1.2rem;
                @include tablet {
                    margin-left: 2rem;
                }
            }

            .navbar-start {

                @include desktop {
                    margin: auto;
                }

                .navbar-item-link, .navbar-item {
                    @extend %transitions;
                    @extend %fancy-link;
                    font-weight: 600;
                    text-transform: lowercase;
                    
                    padding: 1rem;
                    @include desktop {
                        padding: 0;
                    }

                    margin-right: 1rem;
                    &:last-child {
                        margin-right: 0;
                    }

                    &:before {
                        background: $white;
                        @include desktop {
                            background: $med-green;
                        }
                    }

                    &:hover {
                        color: $primary-color;
                    }

                }
                .navbar-item-link.iss-active, .navbar-item.is-active {
                    $linkActiveColor: darken( $primary-color, 5% );
                    font-weight: 800;
                    color: $linkActiveColor;
                    pointer-events: none;
                    &:before {
                        content: "";
                        position: absolute;
                        width: 100%;
                        height: 2px;
                        bottom: -2px;
                        left: 0;
                        background-color: $linkActiveColor;
                        visibility: visible;
                        @include vendor(transform,scaleX(1));
                      }
                }
    
            }
    
            a {
                &.is-navbar-link {
                    font-size: 1rem;
                    margin-top: -4px;
                }
            }
        
            a.navbar-item {
                cursor: pointer;
                font-weight: 400;
                font-size: 1rem;
            }
    
            .navbar-burger {
                height: 4.85rem;
            }
    
            .navbar-item > img {
                margin: 6px 0;
            }

        }

    }

}