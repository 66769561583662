// Transitions
@mixin transitions($property:all,$time:.2s,$mode:linear,$delay:0s) {
    @include vendor(transition, $time $mode $property $delay);
}

%transitions {
    @include transitions(all,.3s,ease-in);
}

%transitions-slow {
    @include transitions(all,1.5s,ease-in);
}

%fancy-link {
    position: relative;
    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 2px;
      bottom: -2px;
      left: 0;
      background-color: $white;
      visibility: hidden;
      @include vendor(transform,scaleX(0));
      @include transitions( all, 0.3s, ease-in-out );
    }
    &:hover {
        &:before {
            visibility: visible;
            @include vendor(transform,scaleX(1));
        }
    }
}

@keyframes placeHolderShimmer {
    0% {
        background-position: -850px 0
    }
    100% {
        background-position: 850px 0
    }
}

%animated-background {
    &:after {
        content: '';
        display: block;
        animation-duration: 1.2s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-name: placeHolderShimmer;
        animation-timing-function: linear;
        background: #f2f2f2;
        background: linear-gradient(to right, #f2f2f2 8%, #F0F0F0 18%, #f2f2f2 33%);
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }
}