// Update Bulma's global variables
$copyFamily: 'Overpass';
$family-sans-serif: $copyFamily, sans-serif;
$family-title-sans-serif: "Glober", sans-serif;
$grey-dark: $brown;
$grey-light: $beige-light;
$primary: $primary-color;
$secondary: $sec-color;
$link: $sec-color;
$widescreen-enabled: false;
$fullhd-enabled: false;
$text: #33343d;
$notification-padding: 0.8rem 1rem;
$subtitle-family: $copyFamily;
$subtitle-color: $dark-grey; //#66676e;
$subtitle-weight: 400;
$title-color: #33343D;
$title-weight: 700;

// Logo
$navbar-item-img-max-height: 3.1rem;

// Update Bulma's input variables
//$body-background-color: $white;
//$control-border-width: 2px;
//$input-border-color: transparent;
$input-shadow: none;

// Bulma Generic vars
$body-font-size: 1.3rem;
$body-weight: 400;

// Section
$section-padding-mobile: 1.75rem;
$section-padding: 3.5rem;

// Page Footer
$footer-padding: 0;
$footer-background-color: #fff;

//Navbar
$navbar-item-color: #33343d;
$navbar-dropdown-border-top: solid 2px $med-green;
$input-focus-border-color: $med-green;
$input-focus-box-shadow-color: rgba( $med-green,0.25);
$navbar-item-hover-background-color: #fff;
$navbar-box-shadow-size: 0 1px 0 0;  

// Tabs
$tabs-border-bottom-width: 3px;

// Tags
$tag-background-color: #f4f4f4;

// Buttons
$button-focus-border-color: none;

// UI Cards
$uicard-border-radius: 10px;

// Breakpoints
$tablet: 768px;

// Links
$link-hover: $primary-color;

// Menu
$navbar-breakpoint: 1216px;