@each $name, $weight in $font-glober {
    @font-face {
        font-family: 'Glober';
        src: url( "/_fonts/glober/#{$name}.otf" ) format("opentype");
        font-weight: $weight;
        font-style: bold;
        font-display: swap;
    }
}

@each $name, $weight in $font-overpass {
    @font-face {
        font-family: 'Overpass';
        font-style: normal;
        font-weight: $weight;
        src: local('Overpass #{$name}'), local('Overpass-#{$name}'),
            url('/_fonts/overpass/overpass-v5-latin-#{$weight}.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
            url('/_fonts/overpass/overpass-v5-latin-#{$weight}.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
        font-display: swap;
    }
} 