.mc {
    .select {
        &.is-full {
            min-width: 100%;
            select {
                width: 100%;
            }
        }
        &.is-light {
            select {
                color: $lightest-green;
            }
            &:after {
                border-color: $lightest-green !important;
                &:hover {
                    border-color: $white;
                }
            }
        }
    }
}

.mc {
    .form-field > select {
        display: none;
    }
    .is-select-full {
        display: none;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        select {
            width: 100%;
        }
    }
}