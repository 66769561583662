// Image Card
.mc {

    %card-meta {
        font-size: 0.9rem;
        color: #99999e;
        &:first-child::after {
            content: '\2022';
            margin-right: 0.2rem;
            margin-left: 0.2rem;
        }
    }

    %card-title {
        font-weight:800;
        font-size: 1.5rem;
        line-height: 1.9rem;
    }

    .image-cards, %image-cards {
        @extend .columns;
        &.is-cards-half-tablet {
            @include tablet {
                flex-wrap: wrap;
            }
            @include desktop {
                flex-wrap: initial;
            }
            .image-card {
                @include tablet {
                    flex-basis: 50%;
                }
                @include desktop {
                    flex-basis: 1;
                }
            }
        }
    }

    .image-cards-multi {
        @extend %image-cards;
        @extend .is-multiline;
    }

    .image-card-basic {
        @extend .column;
        display: flex;
        flex-direction: column;
        border-radius: 5px;
        background: #fff;
        padding: 2rem;
        .meta {
            @extend %card-meta;
        }
        a {
            @extend %card-title;
        }
    }

    .image-card {
        @extend .column;
        display: flex;
        flex-direction: column;

        > div:first-child {
            border-top-left-radius: $uicard-border-radius;
            border-top-right-radius: $uicard-border-radius;
        }

        > div:last-child {
            border-bottom-left-radius: $uicard-border-radius;
            border-bottom-right-radius: $uicard-border-radius;
        }

        &-head {
            flex-grow: 0;
            flex-shrink: 0;
            width: 100%;
            height: auto;
            overflow: hidden;
            //background: #91c3bd;
            background: lighten(#f4f4f4,2%);
            position: relative;
            z-index: 1;
            .is-img {
                width: 100%;
                height: 100%;
                background-size: cover;
                transition: all .5s;
                background-position: center;
                img {
                    width: 60%;
                }
                &:hover {
                    transform: scale(1.2);
                }
            }
            .is-labels {
                position: absolute;
                width: 90%;
                left: 1rem;
                top: 0.9rem;
                z-index: 9;
            }
        }
        &-body {
            .tag {
                margin-bottom: 1rem;
                //@extend .is-rounded;
            }
            padding: 1.5rem;
            flex-grow: 1;
            flex-shrink: 0;
            a:not(.button) {
                font-weight:900;
                font-size: 1.6rem;
                line-height: 1.9rem;
            }
            background: lighten(#f4f4f4,2%);
            i, svg {
                color: $med-green;
                margin: 0 0.5rem;
                vertical-align: middle;
                &:first-child {
                    margin-left: 0;
                }
            }

            .content-excerpt {
                @extend %dont-break-out;
                max-width: 700px;
                font-size: 1.1rem;
            }
            &-meta {
                margin: 1rem 0;
                i, svg {
                    font-size: 1rem;
                    color: $med-green;
                    margin: 0 0.5rem;
                    vertical-align: middle;
                    &:first-child {
                        margin-left: 0;
                    }
                }
                span.meta {
                    color: $med-green;
                    font-size: 0.9rem;
                    font-weight: 500;
                }
                //background: $lightest-green;
                //padding-bottom: 0.75rem;
                //border-bottom: solid 1px $lightest-green;
            }
        }
        &-foot {
            padding: 0 1.5rem 1.5rem 1.5rem;
            flex-grow: 0;
            flex-shrink: 0;
            i, svg {
                margin-right: 6px;
                color: #76B4AD;
            }
            span.copy {
                font-size: 0.87rem;
                color:#76B4AD;
            }
            background: lighten(#f4f4f4,2%);
        }

        &.is-light-card {
            .image-card-body {
                background: #fff;
            }
            .image-card-foot {
                background: #fff;
            }
        }

    }

    .image-card-alt {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 1.5rem;
        border-radius: $uicard-border-radius;
        overflow: hidden;
        &:last-child {
            margin-bottom: 0;
        }
        & > .card-featured-img {
            overflow: hidden;
            background: #91c3bd;
            flex: 1 1 100%;
            @include tablet {
                flex: 1 1 10ch;
            }
            position:relative;
            .is-img {
                width: 100%;
                height: 100%;
                background-size: cover;
                transition: all .5s;
                background-position: center;
                img {
                    width: 60%;
                }
                &:hover {
                    transform: scale(1.2);
                }
            }
            .is-labels {
                position: absolute;
                width: 90%;
                left: 1rem;
                top: 0.9rem;
                z-index: 9;
            }
        }
        & > .card-content {
            p {
                a {
                    font-weight: 900;
                    font-size: 1.6rem;
                    line-height: 1.9rem;
                }
            }
            padding: 1.5rem;
            background: #fff;
            flex: 4 1 10ch;
            .meta {
                font-size: 0.9rem;
                color: #99999e;
                &:first-child::after {
                    content: '\2022';
                    margin-right: 0.2rem;
                    margin-left: 0.2rem;
                }
            }
            a.card-link {
                font-weight:900;
                font-size:1.75rem;
                line-height: 1.9rem;
            }
        }
    }

    %card-placeholder-bg {
        @extend %animated-background;
        background:#f2f2f2;
        border-radius:3px;
        position:relative;
        overflow: hidden;
    }

    .card-placeholder {
        @extend %card-placeholder-bg;
        &-title {
            height:40px;
        }
        &-meta {
            height:20px;
            margin: 1rem 0;
            width:50%;
        }
        &-excerpt {
            height:200px;
        }
        &-tag {
            margin-right:.5rem;
            width:75px;
        }
    }

    %listing-ph {
        @extend %animated-background;
        position: relative;
        overflow: hidden;
        border-radius: 3px;
    }

    .listing-placeholder {
        &-category {
            width: 10%;
            @extend %listing-ph;
            height: 25px;
        }
        &-title {
            width: 65%;
            @extend %listing-ph;
            height: 40px;
            margin-top: .5rem;
        }
        &-subtitle {
            width: 45%;
            @extend %listing-ph;
            height: 35px;
            margin-top: .5rem
        }
    }

}

.mc .is-meta {
    background: $light-green;
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
}