// Set your brand colors
$primary-color: #76b4ad;
$sec-color: #f28f79;
$brown: #757763;
$beige-light: #D0D1CD;
$beige-lighter: #EFF0EB;
$white: #fff;
$dark-grey: lighten( #4a4a4a, 10%); //#43414e; 
$light-grey: lighten(#f4f4f4,2%);
$med-green: #91c3bd;
$light-green: lighten(#E9F0EC,2%);
$white: #fff;

$lightest-green: #f5f8f6;