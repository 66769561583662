// Articles
.mc article.is-article address.author {
    display: inline;
    font-style: normal;
    font-weight: 600;
}

.mc article.is-article ul.is-meta-list {
    list-style-type: none;
    color: #777;
    font-weight: 500;
    margin: 0.5rem 0;
}

.mc article.is-article ul.is-meta-list li {
    display: inline-block;
    margin-right: 1rem;
}

.mc article.is-article ul.is-meta-list li > .copy {
    margin-left: 0.5rem;
}

.mc article.is-article ul.is-meta-list li.is-meta-cat:after,
.mc article.is-article ul.is-meta-list li:last-child:after {
    content: "";
}