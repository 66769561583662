h1, h2, h3, h4, h5 {
    font-family: $family-title-sans-serif;
}

.mc .is-header-divider::after {
    display: block;
    width: 100px;
    height: 5px;
    background: $primary;
    content: '';
    margin: 2rem 0 2.75rem 0;
}