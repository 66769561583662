.mc {
    
    .is-inline-list li:first-child a {
        padding-left: 0.75rem;
    }
    
    .breadcrumb {
        @extend .breadcrumb;
        li {
            &:first-child {
                span {
                    &.icon {
                        font-size: 140%;
                        margin-right: 0;
                    }
                }
                a {
                    &:first-child {
                        padding: 0 !important;
                        margin-left: 0;
                    }
                }
            }
            margin-bottom:0.75rem;
            i,svg {
                color: lighten($med-green,25%) !important;
            }
            a {
                @extend %fancy-link;
                text-transform: uppercase;
                font-weight: 600;
                color: lighten($med-green,25%) !important;
                padding: 0;
                margin: 0 0.75rem;
            }
            &.is-active {
                &:first-child {
                    a {
                        padding: 0 0.75rem !important;
                    }
                }
                &.is-event-active {
                    a {
                        pointer-events: auto;
                        cursor: pointer; 
                        &:before {
                            visibility: hidden !important;
                        }
                    }
                }
                a {
                    background: darken($med-green,10%);    
                    font-weight: 900;
                    color: $white !important;
                    pointer-events: none;
                    border-radius: 5px;
                    margin-left: 1rem;
                    padding: 0 0.75rem;
                }
            }
        }
    }

    .is-section-breadcrumb {
        background: $med-green;
        @extend .is-padding-top-2;
        @extend .is-padding-bottom-1;
    }

}