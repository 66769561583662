.mc {
    .column {
        &.is-content-background {
            background: $med-green;
        }
        &.is-content-background-sec {
            background: $primary-color;
        }
    }

    %is-content-block {
        @extend .is-padding-top-5;
        @extend .is-padding-bottom-6;
    }

    .is-content-block-main {
        //@extend %is-content-block;
        background: $lightest-green !important;
    }

    .is-content-block-alternate {
        @extend %is-content-block;
        background: $light-green;
    }

    .is-content-block-secondary {
        //@extend %is-content-block;
        background: $light-grey !important;     
    }


}

.mc {
    .columns {
        &.has-divider {
            .column {
                & > div {
                    border-bottom:solid 2px #ebebec;
                    padding-bottom: 2rem;
                }
                &:last-child {
                    & > div {
                        border-bottom: none;
                        padding-bottom: 0;
                    }
                }
                @include tablet {
                    & > div {
                        border-right:solid 2px #ebebec;
                        border-bottom: none;
                        padding-bottom:0;
                    }
                    &:last-child {
                        & > div {
                            border-right: none;
                        }
                    }
                }
            }
        }
    }
}