%horizontal-list {
    display: flex;
    flex-wrap: wrap;
    li {
        margin-right: 1rem;
        &:last-child {
            margin-right: 0;
        }
    }
}

.mc {

    ul {
        &.is-horizontal-list {
            @extend %horizontal-list;
        }

        &.is-articles-meta-list {
            @extend %horizontal-list;
            li {
                &:nth-child(2) {
                    flex-basis: 100%;
                }
            }
        }
    }
}

.mc .is-inline-list {
    @extend .breadcrumb;
    li + li::before {
        content: '/';
    }
    li {
        min-height: 24px;
        a {
            padding: 0;
            margin: 0 0.75rem;
            text-transform: uppercase;
        }
        &:first-child {
            a {
                margin-left: 0;
            }
        }
        &.is-active {
            a {
                font-weight: 700;
            }
        }
    }
}